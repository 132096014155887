import { useMemo } from 'react';
import Head from 'next/head';

import { MedicalTypeEnum } from '@/graphql/generated/graphql';
import { makeDoctorCallName } from '@/utils/makeDoctorCallName';

const HeadComponent = (props: {
  hospitalName?: string;
  doctorName?: string;
  doctorType?: MedicalTypeEnum;
  hospitalAddress?: string;
}) => {
  const { hospitalName, doctorName, doctorType, hospitalAddress } = props;

  const metaData = useMemo(() => {
    return {
      title: `${hospitalName ? `${hospitalName} ` : ''}비대면 진료 간편신청 | ${
        doctorName ? `${doctorName} ${makeDoctorCallName(doctorType)} | ` : ''
      }빠른 비대면 진료는 ‘굿닥’`,
      description: `${hospitalAddress ? `${hospitalAddress} ` : ''}${
        hospitalName ? `${hospitalName}에서 ` : ''
      }코로나19, 감기, 소아청소년과, 여드름, 사후피임약, 위염, 장염, 안구건조, 다이어트, 비염 등 비대면으로 바로 진료 받아보세요.`
    };
  }, [hospitalName, doctorName, doctorType, hospitalAddress]);

  return (
    <Head>
      <title>{metaData.title}</title>
      <meta name="description" content={metaData.description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta property="cache-control" content="no-cache" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="ko_KR" />
      <meta property="og:title" content={metaData.title} />
      <meta property="og:description" content={metaData.description} />
      <meta property="og:image" content="/img_addon_og.png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="1200" />
      <meta property="og:site_name" content="굿닥 비대면진료 간편신청" />
      <meta property="og:locale" content="ko_KR" />
      <meta property="og:url" content="https://untact.goodoc.co.kr" />
      <meta name="twitter:title" content={metaData.title} />
      <meta name="twitter:description" content={metaData.description} />
      <meta name="twitter:url" content="https://untact.goodoc.co.kr" />
      <meta name="twitter:card" content="/img_addon_og.png" />
      <meta name="twitter:image" content="/img_addon_og.png" />
      <meta name="twitter:creator" content="@goodoc" />
      <meta
        name="google-site-verification"
        content="7YO_uwq7T-fUvEKWLDwRZK2fTaCpM8CSHhTwaSyi1l4"
      />
      <meta
        name="naver-site-verification"
        content="a15d5d322cf8932355921ed65319c1fb1561755d"
      />
      <link rel="cannonical" href="https://untact.goodoc.co.kr" />
      <link rel="icon" href="/favicon.png" />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.5/dist/web/static/pretendard-dynamic-subset.css"
      />
    </Head>
  );
};
export default HeadComponent;
